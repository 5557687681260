<template>
  <!-- Login -->
  <login-form
    image="/top-img.png"
    v-bind:err-message="message"
    v-on:submit="login"
  />
</template>

<script>
import LoginForm from '@/components/common/LoginForm'
export default {
  components: {
    LoginForm,
  },
  data() {
    return {
      message: null,
    }
  },
  methods: {
    login(loginData) {
      this.$store
        .dispatch('login', {
          username: loginData['user'],
          password: loginData['password'],
        })
        .then((response) => {
          console.log(response)
          this.$router.push('/').catch((error) => {
            console.log('* redirect msg ok *', error)
          })
        })
        .catch((error) => {
          if (error.response) {
            console.log(error.response)
            this.message = error.response.data.detail
          } else {
            this.message = 'ログインに失敗しました。'
          }
        })
    },
  },
}
</script>
